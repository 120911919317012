const colors = {
  interactive: {
    main: '#000',
    secondary: '#E4F9C7',
  },
  primary: {
    main: '#202e59',
  },
  secondary: {
    main: '#fff',
    picker: '#202e59',
  },
  black: {
    main: '#000',
  },
  gray: {
    main: '#E2E2E2',
    dark: '#939599',
    light: '#F8F8F9',
    text: '#4D4E50',
    darkBorder: '#808080',
  },
  alternative: {
    main: '#E4F9C7',
    contrast: '#EFEFEF',
  },
  error: {
    main: '#FE0009',
  },
  success: {
    main: '#00A411',
  },
  warning: {
    main: '#fbb041',
    icon: '#fbb041',
  },
  background: {
    default: '#fff', // "fff9f4"
  },
  text: {
    primary: '#000',
    main: '#202e59',
    secondary: '#fff',
  },
};

export default colors;
